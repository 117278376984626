*,
        *::before,
        *::after {
            box-sizing: border-box
        }

        .tekst {
            color: #505050;
            margin: 0;
            padding: 0;
            font-family: 'Lato', arial, sans-serif;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 28px;
            font-weight: bold;
            display: block;
            letter-spacing: 2px;
            max-width: 100%;
            text-align: center;
            position: absolute;
            bottom: 20px;
            left: 30px;
            z-index: 1
        }


        .pozycja {
            position: fixed;
            top: 50%;
            left: 50%;
            height: 100%;
            transform: translate(-111px, -111px)
        }

        .ludek {

            width: 200px;
            height: 100%
        }

        .ludek .cialo,
        .skora {
            position: fixed;
            width: 100%;
            height: 105%;
            top: 0;
            left: 0;
            background:#61677C;
            border-radius: 200px 200px 0 0;
            transform: rotate(0deg);
            -webkit-transform-origin: bottom center;
            -moz-transform-origin: bottom center;
            -ms-transform-origin: bottom center;
            -o-transform-origin: bottom center;
            transform-origin: bottom center
        }

        .ludek .skora {
            overflow: hidden
        }

        .ludek .cialo .ucho {
            position: absolute;
            top: 80px;
            width: 45px;
            height: 45px;
            background: #61677C;
            border-radius: 45px
        }

        .ludek .cialo .ucho.lewe {
            left: -10%
        }

        .ludek .cialo .ucho.prawe {
            right: -10%
        }

        .ludek .oko {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 85px;
            background: #F9F8FF;
            border-radius: 50%;
            z-index: 1
        }

        .ludek .oko.lewe {
            left: 47px
        }

        .ludek .oko.prawe {
            left: 103px
        }

        .ludek .oko .zrenica {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 18px;
            left: 18px;
            background: #61677C;
            border-radius: 50%
        }

        .ludek .powieka_gorna {
            width: 70%;
            height: 30px;
            position: absolute;
            top: 50px;
            left: 15%;
            background: #61677C;
            border-radius: 30px 30px 0 0;
            z-index: 3
        }
        .crecebot{
            font-size: 1.5rem !important;
        }
        .ludek .powieka_dolna {
            width: 70%;
            height: 30px;
            position: absolute;
            top: 140px;
            left: 15%;
            background: #61677C;
            border-radius: 0 0 30px 30px;
            z-index: 3
        }

        .ludek .usta {
            width: 80%;
            height: 32px;
            position: absolute;
            top: 145px;
            left: 10%;
            background: #F9F8FF;
            border-radius: 32px 32px 32px 32px;
            z-index: 4
        }
        .botonestise{
            flex-direction: column;
            width: 90%;
            display: flex;
            justify-content: space-between;
        }
        .centra{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .midlo{
            width: 100%;
            text-align: center;
            margin-top: 24px;
        }
        .ajis{
            margin-top: 32px;
            flex-direction: column;
            border-radius: 32px;
            max-height: 65px;
            overflow: hidden;
            justify-content: flex-start;
            align-items: center;
        }
        .aji{
            margin-top: 32px;
            flex-direction: column;
            border-radius: 32px;
            max-height: 65px;
            overflow: hidden;
            justify-content: flex-start;
            align-items: center;
        }
        .ajis:hover{
            max-height: 1200px;
        }
        .aji:hover{
            max-height: 500px;
        }
        .bajemos{
            margin-top: 24px;
            font-size: 1.2rem;
            text-align: left;
        }
        .bajemus{
            margin-top: 24px;
            font-size: 1.2rem;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            transition: all 2s;      
        }
        .estaespri{
            width: 1400%;
        }
        .estaesseg{
            width: 700%;
        }
        .estaester{
            width: 1000%;
        }
        .estaescuar{
            width: 200%;
        }
        .centraymargen{
            text-align: center;
            margin-top: 24px;
        }
        .imagenporta{
            width: 100%;
            max-width: 440px;
        }
        .vidporta{
            width: 100%;
            height: 100%;
        }
        .contentslideri{
            width: 100%;
            max-width: 440px;
            overflow: hidden;
        }
        .latrandeho{
            transition: all 2s; 
            position: relative;
        }
        .latrandehos{
            transition: all 2s;
            width: 100% !important;
        }
        .abirlink{
            position: absolute;
            width: 100%;
            text-align: center;
            top: 80px;
            background-color: rgba(255,255,255,0.8);
            padding: 12px;
            transition: all 1s;
            opacity: 0;
        }
        .latrandeho:hover .abirlink{
            opacity: 1;
        }
        @media screen and (min-width: 850px) {
            .botonestise{
                flex-direction: row;
            }
            .midlo {
                width: 33%;
                margin-top: 0px;
            }
        }
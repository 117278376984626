.ajustalcenro {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flotantestext {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  font-size: 1.3rem;
  font-weight: 500;
}
.suavesitosuave {
  transition: all 0.3s;
}
.girameconamor {
  -moz-transform: rotate(90deg) translatey(-4rem);
  -o-transform: rotate(90deg) translatey(-4rem);
  -webkit-transform: rotate(90deg) translatey(-4rem);
  transform: rotate(90deg) translatey(-4rem);
}
.giramecondesdeno {
  -moz-transform: rotate(-90deg) translatey(-7rem);
  -o-transform: rotate(-90deg) translatey(-7rem);
  -webkit-transform: rotate(-90deg) translatey(-7rem);
  transform: rotate(-90deg) translatey(-7rem);
}
.alinealfunal {
  text-align: right;
}
.textalinbordcarg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
svg.tea {
  transform: scale(3);
  --secondary: #33406f;
}

svg.tea #teabag {
  transform-origin: top center;
  transform: rotate(3deg);
  animation: swing 2s infinite;
}

svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13;
  animation: steamLarge 2s infinite;
}

svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9;
  animation: steamSmall 2s infinite;
}

@media screen and (max-width: 600px) {
  .flotantestext {
    font-size: 1rem;
    font-weight: normal;
    padding: 6px;
  }
  .girameconamor {
    -moz-transform: rotate(90deg) translatey(-3rem);
    -o-transform: rotate(90deg) translatey(-3rem);
    -webkit-transform: rotate(90deg) translatey(-3rem);
    transform: rotate(90deg) translatey(-3rem);
  }
  .giramecondesdeno {
    -moz-transform: rotate(-90deg) translatey(-5rem);
    -o-transform: rotate(-90deg) translatey(-5rem);
    -webkit-transform: rotate(-90deg) translatey(-5rem);
    transform: rotate(-90deg) translatey(-5rem);
  }
}

@-moz-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-webkit-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-o-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}

@-moz-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-webkit-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-o-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }

  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}

@-moz-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-webkit-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@-o-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }

  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }

  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}

@font-face {
  font-family: "Nova Square";
  font-style: normal;
  font-weight: normal;
  src: url("./NovaSquare-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  touch-action: manipulation;
  font-display: auto;
}
*::selection {
  background-color: #908db1;
  color: #fff;
}
*::-moz-selection {
  background-color: #908db1;
  color: #fff;
}
body::-webkit-scrollbar {
  background: #b7b4cb;
  width: 4px;
}
body::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 100px;
}
input {
  cursor: url(cursor/cursor.cur), auto;
}
body {
  cursor: url(cursor/cursor.cur), auto;
  background-color: #b7b4cb;
  background-image: linear-gradient(to bottom left, #b7b4cb, #fff);
  background-size: 100vw 100vh;
  background-attachment: fixed;
  color: #61677c;
  width: 100vw;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
@media screen and (max-width: 800px) {
  body {
    background-size: 100vw 130vh;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input,
select,
textarea {
  background-color: #f2f2f2;
}

input:focus {
  outline: 0px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #61677c;
  transition: all 1s;
  transition-timing-function: linear;
  border-radius: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator:hover {
  background-color: #7a8fae;

  color: #fff;
  cursor: url(cursor/pointer.cur), auto;
}
a:-webkit-any-link {
  cursor: url(cursor/pointer.cur), auto;
}
.sinpunter {
  cursor: auto !important;
  text-align: left !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.padre {
  min-height: 100vh;
  transition: 2s all;
  transition-timing-function: linear;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
svg {
  overflow: visible;
  transition: all 0.05s;
  transition-timing-function: linear;
}
.imagenlo {
  width: 100%;
  transition: all 1.2s;
  transition-timing-function: linear;
}
.imagenlo:hover {
  transform: scale(1.1);
}
.logo {
  width: 50%;
  max-width: 150px;
  min-height: 45px;
  min-width: 38px;
}
.bloquevisu {
  position: fixed;
  z-index: -1;
  top: 0;
  transition: 2s all;
  transition-timing-function: linear;
  opacity: 1;
}
.soyimg {
  position: absolute;
  transition: all 0.1s linear;
}
.cls-1,
.cls-9 {
  fill: #a3c1e0;
}
.cls-2,
.cls-10 {
  fill: #f9f8ff;
}
.cls-3,
.cls-11 {
  fill: #7a8fae;
}
.cls-4,
.cls-12 {
  fill: #93add9;
}
.cls-5,
.cls-13 {
  fill: #b4c8e5;
}
.cls-6,
.cls-14 {
  fill: #908db1;
}
.cls-7,
.cls-8,
.cls-15 {
  fill: #9dd5de;
}
.dejamepe {
  font-size: 1rem;
}
.cabecera {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 5% 0px 5%;

  background-attachment: fixed;
  top: 0;
}
.elrecdelacot {
  transition: all 1s;
  max-height: 5rem;
  overflow: hidden;
}
.elrecdelacot:hover {
  max-height: 1200px;
  cursor: url(cursor/pointer.cur), auto;
}

.elrecdelacota {
  transition: all 1s;
  max-height: 7rem;
  overflow: hidden;
}
.elrecdelacota:hover {
  max-height: 1200px;
  cursor: url(cursor/pointer.cur), auto;
}
.xinservible {
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: url(cursor/pointer.cur), auto;
}

.elrecdelacot .textotimipe .vanlosiconos {
  display: none;
}
.elrecdelacot:hover .textotimipe .vanlosiconos {
  display: flex;
}
.elrecdelacota .textotimipe .vanlosiconos {
  display: none;
}
.elrecdelacota:hover .textotimipe .vanlosiconos {
  display: flex;
}
.textos {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 300px;
  min-height: 300px;
  align-items: center;
  margin-top: -300vh;
  z-index: 1;
  background-color: #f2f2f2;
  box-shadow: 5px -6px 13px rgba(0, 0, 0, 0.4);
  padding: 32px 5vw 8% 5vw;
  border-radius: 0 0 500px 500px;
  transition: 2s all;
  transition-timing-function: linear;
}
.abretecesamo {
  margin-top: -20px;
  z-index: 2;
}

.huecomenulado {
  display: block;
  cursor: url(cursor/pointer.cur), auto;
  transition: 2s all;
  transition-timing-function: linear;
}
#menu_on {
  display: flex;
  z-index: 1;
  flex-direction: column !important;
  padding: 1vw;
  width: 11vw;
  height: 11vw;
  position: relative;
  z-index: 2;
  max-width: 11vh;
  max-height: 10vh;
  min-height: 45px;
  min-width: 38px;
}
.error {
  background-color: #ffb6c1;
  border-radius: 10px;
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 24px;
}
.menun {
  z-index: 1;
  padding: 1vw;
  width: 11vw;
  height: 11vw;
  position: relative;
  z-index: 2;
  max-width: 11vh;
  max-height: 10vh;
  min-height: 45px;
  min-width: 38px;
  filter: drop-shadow(2px -2px 2px rgba(0, 0, 0, 0.4));
}
.barras {
  width: 80%;
  height: 7px;
  min-width: 38px;
  max-width: 11vh;
  border-radius: 10px;
  max-height: 1.6vh;
  margin: 0;
  flex-direction: column;
  position: absolute;
  background-color: #7a8fae;
  transform: rotate(0deg);
  transition: all 1s;
  transition-timing-function: linear;
  top: calc(10%);
  min-height: 4px;
  box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.4);
}

.barrasin {
  width: 80%;
  height: 6px;
  min-width: 38px;
  max-width: 11vh;
  border-radius: 10px;
  max-height: 1.6vh;
  margin: 0;
  flex-direction: column;
  position: absolute;
  background-color: #7a8fae;
  transform: rotate(0deg);
  transition: all 1s;
  transition-timing-function: linear;
  top: calc(10%);
  min-height: 4px;
}
.cruzderecha {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: calc(50% - 1vw);
}
.cruzizquierda {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: calc(50% - 1vw);
}
.cruzbaja {
  opacity: 0;
  top: calc(50% - 1vw);
}
.cruerpderecho {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: calc(35% - 1vw);
  left: calc(0% - 1vw);
  width: 50%;
}
.cuerpizquierda {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: calc(70% - 1vw);
  left: calc(0% - 1vw);
  width: 50%;
}
.cuerpobaja {
  opacity: 1;
  top: calc(52% - 1vw);
  left: calc(11% - 1vw);
}
.barras:nth-child(2) {
  top: calc(50% - 1vw);
}
.barras:nth-child(3) {
  top: calc(90% - 2vw);
}

.galeriacont {
  height: 32vw;
}
.imagina {
  width: 32vw;
  height: 32vw;
}
.cabetext {
  color: rgba(0, 0, 0, 0.4);
  transition-timing-function: linear;
  font-family: "Nova Square", cursive;
  font-size: 1.3rem;
  text-align: center;
  transition: all 0.5s;
}
.elboton:hover .cabetext {
  color: #61677c;
  text-shadow: inset 0px 0px 2px #61677c;
}
.elbotona:hover .cabetext {
  color: #61677c;
  text-shadow: inset 0px 0px 2px #61677c;
}
.lectorm {
  width: 100vw;
  height: 100vw;
  z-index: 2;
  position: absolute;
}
.cuerpo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5%;
  padding-top: 32px;
  opacity: 1;
  justify-content: space-around;
  transition: all 1s;
  transition-timing-function: linear;
  margin-bottom: 32px;
}
.titulopre {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px -0px 12px rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  color: #61677c;
  transition: all 1s;
  transition-timing-function: linear;
  font-family: "Nova Square", cursive;
  font-size: 2rem;
  text-align: center;
  padding: 16px 5% 16px 5%;
  width: 100%;
}
.titulopres {
  background-color: #f2f2f2;
  border-radius: 10px;
  color: #61677c;
  transition: all 1s;
  transition-timing-function: linear;
  font-family: "Nova Square", cursive;
  font-size: 2rem;
  text-align: center;
  padding: 16px 5% 16px 5%;
  width: 100%;
}
.cuerpopre {
  width: 48%;
  padding: 16px 5% 16px 5%;
  margin-top: 25px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  color: #61677c;
  box-shadow: 0px -0px 12px rgba(255, 255, 255, 0.8);
  transition: all 1s;
  transition-timing-function: linear;
}
.cuerpopre:hover {
  cursor: url(cursor/pointer.cur), auto;
  background-color: rgba(255, 255, 255, 1);
}

.soylaimagen {
  width: 100%;
}
.laimagen {
  width: 50%;
  margin-bottom: 12px;
}
.eltexto {
  font-family: "Nova Square", cursive;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.5),
    -3px -3px 3px rgba(255, 255, 255, 0.5);
  padding: 6px;
}
.pregunlogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding: 5%;
  margin: auto;
  font-family: "Nova Square", cursive;
  font-size: 1.5rem;
  background-color: #f2f2f2;
  border-radius: 10px;
  transition: all 2s;
  transition-timing-function: linear;
  margin-top: 24px;
}
.flotalert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding: calc(5% + 12px) 5% 5% 5%;
  font-family: "Nova Square", cursive;
  font-size: 1.5rem;
  background-color: #f2f2f2;
  border-radius: 10px;
  transition: all 2s;
  transition-timing-function: linear;
  position: relative;
}
.iconosconfirm {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
}
.ajustatext {
  font-size: 1.3rem;
  text-align: left;
  margin-top: 32px;
}

.preguncuest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  font-size: 1.5rem;
  margin: auto;
  font-family: "Nova Square", cursive;
  padding: 12px;
  padding-top: 32px;
}

.ilinput {
  width: 100%;
  padding: 16px;
  border: 0;
  margin-bottom: 32px;
  font-size: 1rem;
  font-family: "Nova Square", cursive;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 10px #fff;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  border-radius: 32px;
  text-shadow: 1px 1px 0 #fff;
}
.ilinput:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.eltidiliput {
  font-size: 1.3rem;
  margin-bottom: 24px;
}
.eltitu {
  font-size: 2rem;
  margin-bottom: 32px;
  justify-content: center;
  display: flex;
  font-weight: 400;
}
.eltitulon {
  width: 90%;
  margin: auto;
  font-size: 1.5rem !important;
  text-align: center !important;
  display: flex;
  padding: 12px;
}
.eltituy {
  font-size: 1.5rem;
  justify-content: center;
  display: flex;
}

.elboton {
  justify-content: center;
  display: flex;
  color: #61677c;
  font-size: 1.5rem;
  font-family: "Nova Square", cursive;
  border: 0;
  border-radius: 32px;
  padding: 16px;
  text-shadow: 1px 1px 1px #fff;
  transition: all 1.3s;
  transition-timing-function: linear;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  background-color: #f2f2f2;
  text-shadow: 1px 1px 0 #fff;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
  transition: all 0.2s ease-in-out;
}
.elbotona {
  display: flex;
  color: #61677c;
  font-size: 1.5rem;
  font-family: "Nova Square", cursive;
  border: 0;
  border-radius: 32px;
  padding: 16px;
  text-shadow: 1px 1px 1px #fff;
  transition: all 1.3s;
  transition-timing-function: linear;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  background-color: #f2f2f2;
  text-shadow: 1px 1px 0 #fff;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #babecc;
  transition: all 0.2s ease-in-out;
}
.centratex {
  text-align: center;
}
.elboton:hover {
  box-shadow: -2px -2px 5px #fff, 2px 2px 5px #babecc;
  cursor: url(cursor/pointer.cur), auto;
}
.elboton:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}
.elbotona:hover {
  box-shadow: -2px -2px 5px #fff, 2px 2px 5px #babecc;
  cursor: url(cursor/pointer.cur), auto;
}
.elbotona:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.subale {
  margin-top: 32px;
}
.aumentapaddi {
  margin-bottom: 32px;
  cursor: url(cursor/pointer.cur), auto;
}
.elcontplan {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.texcuerpo {
  text-align: left;
  font-size: 1rem;
}
.flotanteexpli {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5vw;
  padding-top: 13vw;
  transition: all 2s;
  transition-timing-function: linear;
  z-index: 6;
}
.flotanteexplic {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #f2f2f2;
  padding: 5vw;
  padding-top: 13vw;
  transition: all 2s;
  transition-timing-function: linear;
  z-index: 6;
}

.quitahe {
  height: auto !important;
}
.flotanterest {
  z-index: 3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 16px 5% 16px 5%;
  transition: all 2s;
  transition-timing-function: linear;
}
.eliconopregunta {
  width: 6rem;
  position: fixed;
  right: 12px;
  bottom: 12px;
  cursor: url(cursor/pointer.cur), auto;
  text-align: center;
  filter: drop-shadow(0 0 20px #7a8fae);
  z-index: 1;
}
.eliconopreguntan {
  width: 6rem;
  position: absolute;
  right: 0;
  top: 0;
  cursor: url(cursor/pointer.cur), auto;
}
.yosoyelclick {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
}
.realtivo {
  position: relative;
}
.centrico {
  align-items: center !important;
  overflow: visible !important;
}
.traeidioma {
  width: 33%;
  display: flex;
  justify-content: center;
}

.contanidi {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.textodetit {
  font-weight: 400;
  padding-bottom: 24px;
  text-align: center;
}
.eliconopregu {
  width: 6rem;
  right: 0;
  bottom: 0;
  cursor: url(cursor/pointer.cur), auto;
  margin-right: 24px;
  margin-left: 24px;
}
.contflota {
  width: 99%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.3rem;
  font-family: "Nova Square", cursive;
  overflow-y: auto;
  flex-direction: column;
}
.contflotat {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-family: "Nova Square", cursive;
  overflow-y: auto;
  flex-direction: column;
}
.textoderesult {
  width: 100%;
  text-align: left;
}
.textoderesultin {
  width: 100%;
  text-align: left;
  font-size: 1rem;
}

.contflota::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}
.contflota::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
.contflotat::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}
.contflotat::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
.latextarea::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 4px;
}
.latextarea::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
.textnormal::-webkit-scrollbar {
  background: #fff;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 6px;
}
.textnormal::-webkit-scrollbar-thumb {
  background: rgba(122, 143, 174, 1);
  width: 1px;
  border-radius: 100px;
}
.conchat::-webkit-scrollbar {
  background: #fff;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 6px;
}
.conchat::-webkit-scrollbar-thumb {
  background: rgba(122, 143, 174, 1);
  width: 1px;
  border-radius: 100px;
}
.contenidoperfil {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding-bottom: 32px;
  border-bottom: solid #999;
}
.conchat {
  width: 90vw;
  font-size: 1rem !important;
  height: 90vh;
  overflow-y: auto;
  position: inherit;
  padding-right: 12px;
  margin-top: 0px !important;
}
.textnormal {
  font-size: 1rem !important;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-right: 12px;
  width: 100%;
}
.textosdatosuser {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.punticos {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.conboton {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.vanlosiconos {
  text-align: center;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2rem;
  margin-top: 32px;
}
.iconopuntual {
  margin-left: 16px;
  transition: all 1s;
  transition-timing-function: linear;
  cursor: url(cursor/pointer.cur), auto;
  color: #908db1;
  text-align: center;
}
.textoicono {
  font-size: 1rem;
  text-align: center;
}
.textotimipe {
  display: flex;
  margin-top: 32px;
}
.mandamelade {
  justify-content: flex-start;
  flex-direction: row !important;
  border: 0;
  padding: 0;
  cursor: url(cursor/pointer.cur), auto;
  overflow: hidden;
  position: relative;
}
.textoexpli {
  margin-left: 16px;
  text-align: left;
}
.lineasupe {
  border-top: solid #999;
  padding-top: 32px;
}
.iconopuntual:hover {
  color: #61677c;
}
.marabajo {
  margin-bottom: 32px;
}

.elho {
  color: #908db1;
  transition: all 1s;
  transition-timing-function: linear;
  cursor: url(cursor/pointer.cur), auto;
}
option:hover {
  background-color: #908db1;
}
.elho:hover {
  color: #61677c;
}
.mandamelade:hover .elho {
  color: #61677c;
}
.editusericon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 3rem;
}
.textocuestio {
  margin-bottom: 32px;
  text-align: left;
}
.soypreguntas {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textoderesult {
  font-size: 1.5rem;
  text-align: left;
  margin-top: 24px;
}
.textoderesultin {
  margin-bottom: 24px;
  text-align: left;
  margin-top: 24px;
}
.estitul {
  font-size: 2rem;
  margin-bottom: 24px;
}
.isaplan {
  border-radius: 10px;
  color: #fff;
  width: 32%;
  padding: 10px;
  font-size: 1rem;
  text-align: left;
}
.primplan {
  background-color: #7a8fae;
}
.seguplan {
  background-color: #9dd5de;
}
.tercplan {
  background-color: #908db1;
}
.tituloplan {
  font-size: 1.8rem;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}
.gnoir {
  font-weight: 400;
  font-size: 1.5rem;
}
.marariba {
  margin-top: 24px;
}
.estadentro {
  width: 100%;
  margin-top: 24px;
}
.masgrande {
  font-size: 3rem;
}
.laimagen.realtivo {
  cursor: url(cursor/pointer.cur), auto;
}
.contienedivs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.minhe {
  max-height: 90vh;
}
.menfinal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 2rem !important;
  font-weight: 400 !important;
  text-align: center !important;
}
.alervisu {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 2rem !important;
  font-weight: 400 !important;
  text-align: center !important;
  position: fixed !important;
  left: 0 !important;
}
.giragira {
  -webkit-animation: 1s rotate linear infinite;
  animation: 1s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.grandegrande {
  -webkit-animation: 1s agranda linear infinite;
  animation: 1s agranda linear infinite;
}
.grandegrandesito {
  -webkit-animation: 1s agrandasinso ease-in-out infinite;
  animation: 1s agrandasinso ease-in-out infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes agranda {
  from {
    transform: scale(1);
    filter: drop-shadow(0 0 20px #7a8fae);
  }
  50% {
    transform: scale(0.5);
    filter: drop-shadow(0 0 0 #7a8fae);
  }
  to {
    transform: scale(1);
    filter: drop-shadow(0 0 20px #7a8fae);
  }
}
@-webkit-keyframes agranda {
  from {
    -webkit-transform: scale(1);
    filter: drop-shadow(0 0 20px #7a8fae);
  }
  50% {
    -webkit-transform: scale(0.5);
    filter: drop-shadow(0 0 0 #7a8fae);
  }
  to {
    -webkit-transform: scale(1);
    filter: drop-shadow(0 0 20px #7a8fae);
  }
}

@keyframes agrandasinso {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@-webkit-keyframes agrandasinso {
  from {
    -webkit-transform: scale(1);
    filter: drop-shadow(0 0 20px #7a8fae);
  }
  50% {
    -webkit-transform: scale(0.5);
    filter: drop-shadow(0 0 0 #7a8fae);
  }
  to {
    -webkit-transform: scale(1);
    filter: drop-shadow(0 0 20px #7a8fae);
  }
}

.fondomobile {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: -1;
  transition: all 1s;
}
.imagenla {
  width: 100%;
}
.elrecuamovetrata {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
}
.solamitad {
  width: 100%;
}
.imagencien {
  width: 100%;
  transition: all 2s;
}
.eltexdequetra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: inherit;
  align-items: center;
  transition: all 1s;
}
.solounnumero {
  font-size: 6rem;
  width: 20%;
}
.solountexto {
  width: 70%;
  text-align: right;
}
.contenelazonas {
  width: 700%;
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  transition: all 2s;
}
.ventadevizual {
  width: 100%;
  overflow: hidden;
}
.eldelosbotones {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  margin-bottom: 24px;
}
.colomora {
  color: #908db1;
  cursor: url(cursor/pointer.cur), auto;
  transition: all 2s;
}
.colomora:hover {
  color: #61677c;
}
.imagendecarga {
  max-width: 50%;
}
.pantacraga {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.agra {
  font-size: 6rem;
}
.conito {
  width: 30%;
  min-width: 70px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
}
.marle {
  width: 70%;
}
.padre {
  transition: all 1s;
}
.estaradentro {
  display: flex;
  align-items: center;
}
.marle {
  margin-left: 12px;
}

.damespacioabajo {
  margin-bottom: 32px;
}
.mrladere {
  margin-right: 24px;
}
.listadeentr {
  margin-bottom: 12px;
  display: flex;
  position: relative;
}
.tapatodo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.agranpoco {
  font-size: 1rem;
}
.alaiz {
  text-align: left;
}
.pequemar {
  margin-top: 0.5rem;
  word-break: break-all;
}
.verla {
  cursor: url(cursor/pointer.cur), auto;
}
.elderriba {
  opacity: 0;
}
.eldebaj {
  margin-top: 0;
  opacity: 1;
}
.nomecambie {
  flex-direction: row;
}
.inchat {
  position: absolute;
  bottom: 0;
  left: 0;
}
.chat {
  list-style: none;
  background: none;
  margin: 0;
  padding: 0 0 60px 0;
  margin-top: 60px;
  margin-bottom: 10px;
}
.chat div {
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat .day {
  position: relative;
  display: block;
  text-align: center;
  color: #908db1;
  height: 37px;
  overflow: visible;
  text-shadow: 7px 0px 0px #f2f2f2, 6px 0px 0px #f2f2f2, 5px 0px 0px #f2f2f2,
    4px 0px 0px #f2f2f2, 3px 0px 0px #f2f2f2, 2px 0px 0px #f2f2f2,
    1px 0px 0px #f2f2f2, 1px 0px 0px #f2f2f2, -1px 0px 0px #f2f2f2,
    -2px 0px 0px #f2f2f2, -3px 0px 0px #f2f2f2, -4px 0px 0px #f2f2f2,
    -5px 0px 0px #f2f2f2, -6px 0px 0px #f2f2f2, -7px 0px 0px #f2f2f2;
  box-shadow: inset 0px -4px 0px #908db1;
  line-height: 38px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.other .msg {
  order: 1;
  border-top-left-radius: 0px;
}

.self {
  justify-content: flex-end;
  align-items: flex-end;
}
.self .msg {
  order: 1;
  border-bottom-right-radius: 0px;
}
.msg {
  max-width: 80%;
  background: white;
  text-align: left;
  min-width: 50px;
  padding: 24px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgba(144, 141, 177, 07);
  border-radius: 32px;
  width: fit-content;
}
.msg p {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  color: #61677c;
}
.msg img {
  position: relative;
  display: block;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #eee;
  transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.msg time {
  text-align: right;
  font-size: 0.9rem;
  color: #908db1;
  margin-top: 3px;
  float: right;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.msg time:before {
  content: "\f017";
  color: #ddd;
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  margin-right: 4px;
}
.ventachat {
  position: fixed;
  bottom: 0;
  width: 90vw;
  flex-direction: row !important;
  transition: all 2s;
}
.quitarmar {
  margin-bottom: 0;
}
.iconoenviar {
  font-size: 3rem;
  margin-left: 12px;
  cursor: url(cursor/pointer.cur), auto;
}
.lallineaand {
  text-decoration: line-through;
}
.sinlinea {
  text-decoration: none;
}
.colm {
  flex-direction: column;
  height: 100vh !important;
}
.lapaiz {
  padding-bottom: "24px";
  text-align: "left";
  width: 100%;
}
.centaygran {
  text-align: "center";
  font-size: "1.5rem";
}
.agrandalafu {
  font-size: 2.5rem;
  color: rgba(0, 0, 0, 0.4);
  transition-timing-function: ease-in-out;
  font-family: "Nova Square", cursive;
  text-align: center;
  margin-right: 24px;
  display: block;
  margin-top: 12px;
}
.agrandalafu:hover {
  color: #61677c;
}
.contienelosdered {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.preguntaquienes {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 70vh;
  justify-content: center;
}
.quitult {
  margin-right: 0;
}
.quitame {
  opacity: 0;
}
.textoredes {
  font-size: 1rem;
}
.emerterxt {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 6px 2px 8px 2px;
  filter: drop-shadow(#fff 0 0 6px);
}
.alert {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 5;
  transition: all 1s;
}
.imageninti {
  max-width: 100vw;
  max-height: 80vh;
}
.losbotonesagrados {
  margin: 12px 0px;
  width: 100%;
  padding: 16px;
  justify-content: flex-start;
}
.cuerlandin {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5%;
  margin-top: 64px;
  flex-direction: row;
  justify-content: space-around;
  transition: all 1s;
}
.cuerlandino {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5%;
  margin-top: 64px;
  flex-direction: column;
  align-items: center;
  transition: all 1s;
  overflow: hidden;
}
.cuerlandina {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5%;
  margin-top: 64px;
  flex-direction: column;
  align-items: center;
  transition: all 1s;
}
.cuerlandinu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5%;
  margin-top: 320px;
  flex-direction: column;
  align-items: center;
  transition: all 1s;
  margin-bottom: 200px;
}
.explhacemos {
  width: 70%;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0px 0px 12px #f2f2f2;
  padding: 24px 5% 52px 5%;
  margin-bottom: 12%;
  transition: all 1s;
}
#soycabeza {
  width: 100%;
  height: 1px;
}
.titdean {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem;
  font-family: "Nova Square", cursive;
  margin-bottom: 64px;
  transform: rotateX(35deg), translateY(-50%);
}
.titdean:hover {
  transform: translateY(0);
}
.titdean span {
  color: #474b5a;
}
.titdean:hover span {
  color: #61677c;
}
.titdean span:nth-child(even) {
  transform: skewY(15deg);
}
.titdean span:nth-child(odd) {
  transform: skewY(-15deg);
  background-color: #fff;
  color: #61677c;
}
.titdean:hover span:nth-child(odd) {
  color: #61677c;
  background-color: #f2f2f2;
  transform: skewY(0deg);
}
.titdean:hover span:nth-child(even) {
  transform: skewY(0deg);
}
.titdean span {
  display: block;
  background-color: #f2f2f2;
  width: 80px;
  height: 80px;
  line-height: 80px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
}
.titdean span:first-child {
  border-radius: 10px 0 0 10px;
}
.titdean span:last-child {
  border-radius: 0 10px 10px 0;
}
.iconoag {
  width: 100%;
  text-align: center;
  font-size: 5rem;
  margin-bottom: 12px;
}
.iconoagran {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 12px;
}
.eltidetarland {
  font-size: 1.3rem;
  font-family: "Nova Square", cursive;
}
.iconoscon {
  width: 10%;
}
.iconoscon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 12%;
  transition: all 1s;
}
.delhover {
  background-color: rgba(242, 242, 242, 0.5);
  box-shadow: 0px 0px 12px rgba(242, 242, 242, 1);
  cursor: url(cursor/pointer.cur), auto;
  border-radius: 100%;
  padding: 12px;
  transition: all 1s;
}
.barradelogos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.divdeimlogos {
  margin-bottom: 12px;
  width: 30%;
}
.logodeloscli {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}
.concli {
  width: 100%;
  background-color: #61677c;
  padding: 5%;
  border-radius: 10px;
  margin-bottom: 12%;
  transition: all 1s;
}
.titdos {
  color: #f2f2f2;
  text-align: center;
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem;
  font-family: "Nova Square", cursive;
  margin-bottom: 24px;
}
.contesubma {
  width: 100%;
  height: 120vh;
  display: flex;
  justify-content: flex-end;
}
.submaflotador {
  position: fixed;
  top: 60%;
  left: 130vw;
  width: 30vw;
  transition: all 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sombrita {
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px -3px 2px);
  width: 100%;
  transition: all 1s;
}
.sombrita:hover {
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px -3px 0.5px);
  transform: scale(1.1);
}
.absolutico {
  position: absolute;
  font-size: 0.4rem;
  margin: auto;
  font-family: "Nova Square", cursive;
}
.scrollzonas {
  color: #61677c;
  position: fixed;
  bottom: 40px;
  font-family: "Nova Square", cursive;
  font-size: 1.3rem;
  background-color: #b7b4cb;
  background-image: linear-gradient(
    to bottom left,
    rgba(183, 180, 203, 0.8),
    rgba(255, 255, 255, 0.8)
  );
  background-attachment: fixed;
  background-size: 100vw 100vh;
  border-radius: 10px;
  padding: 60px 6px 6px 6px;
}
.textosabajorue {
  background-color: #b7b4cb;
  background-image: linear-gradient(
    to bottom left,
    rgba(183, 180, 203, 0.8),
    rgba(255, 255, 255, 0.8)
  );
  background-attachment: fixed;
  background-size: 100vw 100vh;
  border-radius: 10px;
  padding: 6px;
}
.scrollzonas span {
  position: absolute;
  width: 30px;
  height: 50px;
  margin-top: -50px;
  margin-left: 10px;
  border: 4px solid #61677c;
  border-radius: 50px;
  box-sizing: border-box;
}
.scrollzonas span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #61677c;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}
.burbujita {
  height: 25vw;
  width: 25vw;
  max-width: 300px;
  max-height: 300px;
  border-radius: 100%;
  overflow: hidden;
  border: 3px solid #61677c;
  box-shadow: 8px -16px 15px rgba(0, 0, 0, 0.5);
}
.imagenburbujuta {
  height: 100%;
}
.segbur {
  margin-top: 20%;
}
.terbur {
  margin-top: 40%;
}

@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.papadelcirculito {
  width: 90vw;
  height: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  transform: rotate(334.1deg);
}
.iconscont {
  display: flex;
  height: 100%;
  width: 10%;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
}
.descero {
  transform: rotate(0deg);
}
.desuno {
  transform: rotate(25.7deg);
}
.desdos {
  transform: rotate(51.4deg);
}
.destes {
  transform: rotate(77.1deg);
}
.descua {
  transform: rotate(102.8deg);
}
.descin {
  transform: rotate(128.5deg);
}
.desseis {
  transform: rotate(154.2deg);
}
.circos {
  position: absolute;
  border-radius: 100%;
}
.circufue {
  width: 85%;
  height: 85%;
  border: 5px dashed;
  border-color: #474b5a #f2f2f2 #f2f2f2 #474b5a;
  background-color: rgba(96, 102, 123, 0.4);
}
.circuseg {
  width: 70%;
  height: 70%;
  border: 7px solid;
  border-color: #474b5a #f2f2f2 #f2f2f2 #474b5a;
  background-color: rgba(242, 242, 242, 0.5);
}

.circucua {
  width: 48%;
  height: 48%;
  border: 7px dotted;
  border-color: #474b5a #f2f2f2 #f2f2f2 #474b5a;
  background-color: #f2f2f2;
  transition: all 1s;
}
.circucin {
  width: 10%;
  height: 10%;
  background: #60667b;
  border: 8px double;
  border-color: #474b5a #f2f2f2 #f2f2f2 #474b5a;
}
.elresaltador {
  height: 50%;
  width: 12%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: -15px;
  border-radius: 30px;
  border: 3px solid #474b5a;
  transition: all 1s;
}
.escureresalta {
  border-color: #f2f2f2;
  background-color: #60667b;
}
.encierraresalta {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}
.calidadtitle {
  font-family: "Nova Square", cursive;
  font-size: 2rem;
  margin-bottom: 45px;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.8);
  color: #61677c;
  border-radius: 10px;
  text-align: center;
  padding: 12px;
}
.trabajotitle {
  font-family: "Nova Square", cursive;
  font-size: 2rem;
  width: 100%;
  background-color: #61677c;
  color: #f2f2f2;
  border-radius: 10px;
  text-align: center;
  padding: 12px;
}

.labarradegiro {
  width: 90vw;
  height: 12px;
  margin-bottom: 3px;
  background: linear-gradient(
    to left,
    #f2f2f2,
    #f2f2f2 48%,
    #474b5a 49%,
    #474b5a
  );
  -webkit-appearance: none;
  border: 2px solid #474b5a;
  border-radius: 12px;

  display: flex;
  align-items: center;
  z-index: 2;
}
.labarradegiro::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-color: #474b5a;
  border: 2px solid #f2f2f2;
  cursor: url(cursor/pointer.cur), auto;
  transition: all 0.5s;
}
.labarradegiro::-webkit-slider-thumb:hover {
  height: 40px;
  width: 40px;
}
.labarradegiron::-webkit-slider-thumb:hover {
  height: 40px;
  width: 40px;
}

.labarradegiron {
  width: 90vw;
  height: 12px;
  margin-bottom: 3px;
  background: linear-gradient(
    to left,
    #f2f2f2,
    #f2f2f2 48%,
    #474b5a 49%,
    #474b5a
  );
  -webkit-appearance: none;
  border: 2px solid #474b5a;
  border-radius: 12px;

  display: flex;
  align-items: center;
  z-index: 2;
}
.labarradegiron::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background-color: #f2f2f2;
  border: 2px solid #474b5a;
  cursor: url(cursor/pointer.cur), auto;
  transition: all 0.5s;
}
.renivelar {
  transform: rotate(334.1deg);
  transition: all 1s;
}
.textorueda {
  width: 90%;
  padding: 12px;
  background-color: rgba(242, 242, 242, 0.8);
  border-radius: 10px;
  margin-bottom: 32px;
  font-size: 1rem;
  font-family: "Nova Square", cursive;
}
.soyelmalo {
  background-color: #61677c;
  color: #f2f2f2;
}
.soyelbueno {
  background-color: rgba(242, 242, 242, 0.8);
  color: #61677c;
}
.titurieda {
  font-size: 1.6rem;
  margin-bottom: 12px;
}
.lacalidad {
  width: 90vw;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-family: "Nova Square", cursive;
}
.blaquiado {
  color: #f2f2f2;
}

@keyframes preLoad {
  0% {
    background-image: url(backim/cuar.webp);
  }
  10% {
    background-image: url(backim/tercer.webp);
  }
  20% {
    background-image: url(backim/segun.webp);
  }
  30% {
    background-image: url(backim/primer.webp);
  }
  40% {
    background-image: url(backim/complet.webp);
  }
  100% {
    display: none;
  }
}

.book {
  position: relative;
  perspective: 630px;
  perspective-origin: center 50px;
  transform: scale(1.2);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
}
.page {
  width: 210px;
  height: 300px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0px;
  right: 50%;
  transform-origin: 100% 100%;
  border: solid #61677c 2px;
  background-size: 420px 300px;
  background-position: center;
}
.page:nth-child(1) {
  transform: rotateX(60deg) rotateY(3deg);
}
.page:nth-child(2) {
  transform: rotateX(60deg) rotateY(4.5deg);
}
.page:nth-child(3) {
  transform: rotateX(60deg) rotateY(6deg);
  animation: nextPage 25s infinite -24s steps(1);
  background-size: 420px 300px;
  background-position: -2px -2px;
}
.page:nth-child(4) {
  transform: rotateX(60deg) rotateY(177deg);
}
.page:nth-child(5) {
  transform: rotateX(60deg) rotateY(175.5deg);
}
.page:nth-child(6) {
  transform: rotateX(60deg) rotateY(174deg);
  overflow: hidden;
}
.page:nth-child(6)::after {
  content: "";
  width: 210px;
  height: 300px;
  position: absolute;
  top: 0px;
  right: 0%;
  transform-origin: center;
  transform: rotateY(180deg);
  animation: nextPage 25s -20s infinite steps(1);
  background-size: 420px 300px;
  background-position: 100% -2px;
}
@keyframes nextPage {
  0% {
    background-image: url(backim/cuar.webp);
  }
  20% {
    background-image: url(backim/tercer.webp);
  }
  40% {
    background-image: url(backim/segun.webp);
  }
  60% {
    background-image: url(backim/primer.webp);
  }
  80% {
    background-image: url(backim/complet.webp);
  }
}
.gap {
  width: 10px;
  height: 300px;
  background: none;
  transform: rotateX(60deg);
  transform-origin: bottom;
  position: absolute;
  top: 0px;
  left: calc(50% - 5px);
}
.gap::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #555;
  width: 10px;
  height: 5px;
  border-radius: 50%;
}
.flip {
  width: 32px;
  height: 300px;
  position: absolute;
  top: 0px;
  transform-origin: 100% 100%;
  right: 100%;
  border: solid #555;
  border-width: 2px 0px;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  background-size: 420px 300px;
}
.flip::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  height: 100%;
  transform-origin: center;
  background-size: 420px 300px;
}
.flip.flip1 {
  right: 50%;
  animation: flip1 5s infinite ease-in-out;
  border-width: 2px 2px 2px 0;
}
.flip.flip1::after {
  animation: nextFlip1 25s -20s infinite steps(1);
}
.flip:not(.flip1) {
  right: calc(100% - 2px);
  top: -2px;
  transform-origin: right;
  animation: flip2 5s ease-in-out infinite;
}
.flip.flip2::after {
  animation: nextFlip2 25s -20s infinite steps(1);
}
.flip.flip3::after {
  animation: nextFlip3 25s -20s infinite steps(1);
}
.flip.flip4::after {
  animation: nextFlip4 25s -20s infinite steps(1);
}
.flip.flip5::after {
  animation: nextFlip5 25s -20s infinite steps(1);
}
.flip.flip6::after {
  animation: nextFlip6 25s -20s infinite steps(1);
}
.flip.flip7::after {
  animation: nextFlip7 25s -20s infinite steps(1);
}
.flip.flip7 {
  width: 30px;
  border-width: 2px 0px 2px 2px;
}
.flip.flip7::after {
  animation: nextFlip7 25s -20s infinite steps(1);
}
@keyframes flip1 {
  0%,
  20% {
    transform: rotateX(60deg) rotateY(6deg);
  }
  80%,
  100% {
    transform: rotateX(60deg) rotateY(174deg);
  }
}
@keyframes flip2 {
  0%,
  20% {
    transform: rotateY(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-15deg) translateY(0px);
  }
}
@keyframes nextFlip1 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  10% {
    background-image: url(backim/tercer.webp);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  30% {
    background-image: url(backim/segun.webp);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  50% {
    background-image: url(backim/primer.webp);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  70% {
    background-image: url(backim/complet.webp);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  90% {
    background-image: url(backim/cuar.webp);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip2 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  10.5% {
    background-image: url(backim/tercer.webp);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  30.5% {
    background-image: url(backim/segun.webp);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  50.5% {
    background-image: url(backim/primer.webp);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  70.5% {
    background-image: url(backim/complet.webp);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  90.5% {
    background-image: url(backim/cuar.webp);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip3 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  11% {
    background-image: url(backim/tercer.webp);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  31% {
    background-image: url(backim/segun.webp);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  51% {
    background-image: url(backim/primer.webp);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  71% {
    background-image: url(backim/complet.webp);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  91% {
    background-image: url(backim/cuar.webp);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip4 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  11.5% {
    background-image: url(backim/tercer.webp);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  31.5% {
    background-image: url(backim/segun.webp);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  51.5% {
    background-image: url(backim/primer.webp);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  71.5% {
    background-image: url(backim/complet.webp);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  91.5% {
    background-image: url(backim/cuar.webp);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip5 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  12% {
    background-image: url(backim/tercer.webp);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  32% {
    background-image: url(backim/segun.webp);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  52% {
    background-image: url(backim/primer.webp);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  72% {
    background-image: url(backim/complet.webp);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  92% {
    background-image: url(backim/cuar.webp);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip6 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  12.5% {
    background-image: url(backim/tercer.webp);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  32.5% {
    background-image: url(backim/segun.webp);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  52.5% {
    background-image: url(backim/primer.webp);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  72.5% {
    background-image: url(backim/complet.webp);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  92.5% {
    background-image: url(backim/cuar.webp);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip7 {
  0% {
    background-image: url(backim/cuar.webp);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  13% {
    background-image: url(backim/tercer.webp);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(backim/tercer.webp);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  33% {
    background-image: url(backim/segun.webp);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(backim/segun.webp);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  53% {
    background-image: url(backim/primer.webp);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(backim/primer.webp);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  73% {
    background-image: url(backim/complet.webp);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(backim/complet.webp);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  93% {
    background-image: url(backim/cuar.webp);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
}
.contepreunca {
  width: 100%;
  padding: 24px;
  background-color: #f2f2f2cc;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Nova Square", cursive;
  transition: all 3s;
  margin-bottom: 24px;
}
.tipreguland {
  font-size: 1.5rem;
  width: 100%;
}
summary:focus {
  border: 0;
  outline: 0;
}
.texrespuesta {
  border-top: 2px solid #61677c;
  padding-top: 24px;
  margin-top: 24px;
  font-size: 1rem;
}
.barload {
  width: 70%;
  background-color: #61677c;
  border-radius: 32px;

  height: 1.3rem;
  border: 1px solid #61677c;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.llenandobarra {
  height: 100%;
  transition: all 1s;
  background-color: #f2f2f2;
  border-radius: 32px;
}
.contebarcarga {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}
.absoluta {
  position: absolute;
  font-family: "Nova Square", cursive;
}
/* ////////////////////////////////////////////////////////////////empieza el responsive el sitio web donde se manejasn las cosas de pc///////// */
@media screen and (min-width: 850px) {
  .barload {
    width: 50%;
  }
  .contepreunca {
    width: 70%;
  }
  .cuerlandinu {
    margin-top: 320px;
  }
  .textorueda {
    width: 40%;
  }
  .iconscont {
    font-size: 2.2rem;
  }
  .labarradegiro {
    width: 40vw;
  }
  .labarradegiron {
    width: 40vw;
  }
  .lacalidad {
    width: 40vw;
  }
  .papadelcirculito {
    width: 50vw;
    height: 50vw;
  }
  .absolutico {
    font-size: 0.6rem;
  }
  .titdos {
    font-size: 2rem;
  }
  .divdeimlogos {
    width: 23%;
  }
  .iconoagran {
    font-size: 5rem;
  }
  .titdean {
    font-size: 2rem;
  }
  .explhacemos {
    width: 48%;
  }
  .losbotonesagrados {
    padding: 16px 4rem;
  }
  .preguntaquienes {
    width: 50%;
  }
  .agrandalafu {
    font-size: 3rem;
  }
  .ventachat {
    bottom: 7vh;
    width: 80vh;
  }
  .pequemar {
    margin-top: 0;
  }
  .agranpoco {
    font-size: 1.4rem;
  }
  .barras {
    height: 15px;
  }
  .conito {
    width: 20%;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .marle {
    width: 80%;
  }
  .xinservible {
    top: 32px;
    left: 32px;
  }
  .minhe {
    max-height: 60vh;
  }
  .cabetext {
    font-size: 1.5rem;
  }
  .cuerpo {
    margin-bottom: 0px;
  }

  .soypreguntas {
    flex-direction: row;
    justify-content: space-between;
  }
  .vanlosiconos {
    margin-top: 0;
    text-align: center;
  }
  .conboton {
    flex-direction: row;
  }
  .contenidoperfil {
    flex-direction: row;
  }
  .cuerpopre {
    width: 30%;
  }
  .pregunlogin {
    width: 50%;
  }
  .flotalert {
    width: 50%;
  }
  .preguncuest {
    width: 50%;
  }
  .eltitulon {
    width: 70%;
  }
  .dejaser {
    width: 50%;
  }
  .agranda {
    width: 90%;
  }
  .logo {
    width: 30%;
  }
  .flotanteexpli {
    padding: 20vh;
  }
  .conchat {
    width: 80vh;
    height: 80vh;
  }
  .flotanteexplic {
    padding: 20vh;
  }
  .centralo {
    max-width: 50%;
  }
  .estadentro {
    width: 49%;
  }
  .contanidi {
    width: 60%;
  }
  .solountexto {
    font-size: 1.8rem;
  }
  .solounnumero {
    font-size: 8rem;
  }
  .eldelosbotones {
    font-size: 2.5rem;
  }
  .elrecuamovetrata {
    flex-direction: row;
    justify-content: space-between;
  }
  .solamitad {
    width: 50%;
  }
}

@media screen and (min-width: 1100px) {
  .iconscont {
    font-size: 2.5rem;
  }
  .textorueda {
    width: 30%;
  }

  .labarradegiro {
    width: 30vw;
  }
  .labarradegiron {
    width: 30vw;
  }
  .lacalidad {
    width: 30vw;
  }
  .papadelcirculito {
    width: 40vw;
    height: 40vw;
  }
  .explhacemos {
    width: 30%;
  }
  .conito {
    width: 10%;
  }
  .marle {
    width: 90%;
  }
  .pregunlogin {
    width: 40%;
  }
  .flotalert {
    width: 40%;
  }
  .preguncuest {
    width: 40%;
  }
  .eltitulon {
    width: 60%;
  }
  .dejaser {
    width: 40%;
  }
  .agranda {
    width: 90%;
  }
  .centralo {
    max-width: 56%;
  }
  .estadentro {
    width: 30%;
  }
  .solountexto {
    font-size: 2.5rem;
  }
  .solounnumero {
    font-size: 10rem;
  }
  .eldelosbotones {
    font-size: 3rem;
  }
  .barras {
    height: 10px;
  }
}
@media screen and (max-width: 500px) {
  .iconscont {
    font-size: 1.3rem;
  }
  .eltexto {
    font-size: 1rem;
  }
  .titulopre {
    font-size: 1.5rem;
  }
  .titulopres {
    font-size: 1.5rem;
  }
  .eliconopregunta,
  .eliconopreguntan {
    width: 3rem;
  }
  .cuerpobaja {
    opacity: 1;
    top: calc(49% - 1vw);
    left: calc(21% - 1vw);
  }
  .cuerpizquierda {
    transform: rotate(45deg);
    top: calc(79% - 1vw);
    left: calc(0% - 1vw);
    width: 50%;
  }
  .cruerpderecho {
    transform: rotate(-45deg);
    top: calc(19% - 1vw);
    left: calc(0% - 1vw);
    width: 50%;
  }
}
